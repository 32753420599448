import React from 'react'
import { Link } from 'gatsby'
//
import { cn } from '../../lib/helpers'

const Button = props => {
  const {
    to,
    element,
    affect,
    classes,
    triggerClass,
    bg,
    fg,
    hoverBg,
    hoverFg,
    btnType,
    wrapperClasses,
    formName,
    className
  } = props
  console.log(className)

  return (
    <>
      {element && element === 'link' ? (
        <Link
          to={to}
          className={cn(
            classes && classes,
            `${bg} ${fg} transition duration-500 ease-in-out hover:${hoverBg} hover:${hoverFg} font-bold inline-block my-4 mx-auto py-2 px-4 rounded`
          )}
        >
          {props.children}
        </Link>
      ) : (
        <button
          type={btnType && btnType}
          className={cn(
            className
              ? className
              : `${bg} ${fg} transition duration-500 ease-in-out hover:${hoverBg} hover:${hoverFg} font-bold inline-block my-4 mx-auto py-2 px-4 rounded`,
            classes && classes,
            triggerClass && triggerClass
          )}
          form={formName}
        >
          {props.children}
        </button>
      )}
    </>
  )
}

Button.defaultProps = {
  bg: `bg-brand-main`,
  fg: `text-white`,
  hoverBg: `bg-brand-accent`,
  hoverFg: `text-white`,
  btnType: `button`,
  classes: null,
  affect: null,
  element: 'button',
  wrapperClasses: null,
  action: null,
  formName: ''
}
export default Button
