import React from 'react'
//
import { Card } from './cards'
import Button from './button'

const TestimonialCard = props => {
  return (
    <Card>
      <h2 className="font-body font-bold text-4xl">Testimonials</h2>
      <blockquote>
        John and the team are great! The project was delivered on time and on budget. Highly
        recommended.
      </blockquote>
      <Button element={`link`} to={`/about`} fg={`bg-brand-accent`} hoverBg={`bg-brand-main`}>
        More testimonials
      </Button>
    </Card>
  )
}

export default TestimonialCard
