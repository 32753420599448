import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
//
import { cn } from '../../lib/helpers'
import { Card } from './cards'
import Button from './button'
import Modal from './modal'
// import FormQuote from './form-quote'

const QuoteCTACard = props => {
  return (
    <Card>
      <h2 className="font-body font-bold text-xl supersport:text-4xl">Get a free quote!</h2>
      <p>
        Fill out the form with your contact details and tell us a little bit about your project.
        Choose your preferred contact method and we will get in touch to discuss your project.
      </p>
      <Button
        element={`button`}
        fg={`bg-brand-accent`}
        hoverBg={`bg-brand-main`}
        affect={`quoteModal`}
        triggerClass={`open-quote-modal`}
      >
        Request a quote
      </Button>
      <QuoteModal modalName={`quoteModal`} triggerClass={`open-quote-modal`} />
    </Card>
  )
}

function QuoteModal(props) {
  return (
    <Modal
      modalName={props.modalName}
      triggerClass={props.triggerClass}
      classes={cn(`bg-brand-mainalpha90 text-white`)}
      modalTitle={`Request a quote`}
      btnAction={{
        name: `submit`,
        target: `quoteRequest`,
        cta: 'Send'
      }}
    >
      {/* <FormQuote compact darkBg inModal /> */}
    </Modal>
  )
}
export default QuoteCTACard
