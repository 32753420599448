import React, { useEffect, useState } from 'react'
//
import Button from './button'
import useForm from '../../lib/use-form'

const Modal = props => {
  // const [modalOpen, setModalOpen] = useState(false)
  useEffect(() => {
    const trigger = document.querySelectorAll(`.${props.triggerClass}`)
    for (let i = 0; i < trigger.length; i++) {
      trigger[i].addEventListener('click', function(event) {
        event.preventDefault()
        toggleModal()
      })
    }
    const overlay = document.querySelector('.modal-overlay')
    overlay.addEventListener('click', toggleModal)

    const closemodal = document.querySelectorAll('.modal-close')
    for (let i = 0; i < closemodal.length; i++) {
      console.log(closemodal)

      closemodal[i].addEventListener('click', toggleModal)
    }

    document.onkeydown = function(evt) {
      evt = evt || window.event
      let isEscape = false
      if ('key' in evt) {
        isEscape = evt.key === 'Escape' || evt.key === 'Esc'
      } else {
        isEscape = evt.keyCode === 27
      }
      if (isEscape && document.body.classList.contains('modal-active')) {
        toggleModal()
      }
    }

    function toggleModal() {
      const body = document.querySelector('body')
      const header = document.querySelector('.header')

      const modal = document.querySelector(`.${props.modalName}`)
      const modalContainer = document.querySelector(`.${props.modalName} .modal-container`)

      modalContainer.setAttribute('style', `top: ${header.offsetHeight - 10}px;`)
      modal.classList.toggle('opacity-0')
      modal.classList.toggle('pointer-events-none')
      body.classList.toggle('modal-active')
    }
    return () => {
      overlay.removeEventListener('click', toggleModal)
      for (let i = 0; i < closemodal.length; i++) {
        closemodal[i].removeEventListener('click', toggleModal)
      }
      for (let i = 0; i < trigger.length; i++) {
        trigger[i].removeEventListener('click', toggleModal)
      }
    }
  })
  return (
    <div
      className={`modal ${props.modalName} opacity-0 transition duration-300 pointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center z-100`}
      data-modal-name={props.modalName}
    >
      <div className="modal-overlay absolute w-full h-full bg-brand-main opacity-60"></div>

      <div
        className={`modal-container ${props.classes && props.classes} ${(props.containerClassName &&
          props.containerClassName) ||
          'w-11/12 md:max-w-md supersport:max-w-supersport mx-auto rounded shadow-lg z-300 overflow-y-auto'}`}
      >
        <div className="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-2 text-sm z-top">
          <svg
            className="fill-current text-white"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
          </svg>
          <span className="text-sm">(Esc)</span>
        </div>

        {/* Add margin if you want to see some of the overlay behind the modal */}
        <div
          className={`modal-content ${(props.contentClass && props.contentClass) ||
            'py-4 text-left px-6 z-50'}`}
        >
          {/* Title */}
          {props.modalTitle && (
            <div className="flex justify-between items-center pb-3">
              <h3 className="text-xl font-display font-bold superstock:text-2xl">
                {props.modalTitle}
              </h3>
              <div className="modal-close cursor-pointer z-50">
                <svg
                  className="fill-current text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                >
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                </svg>
              </div>
            </div>
          )}

          {props.children}

          {/*Footer*/}
          {props.btnAction && (
            <div className="flex w-full justify-around pt-2">
              <Button
                btnType={props.btnAction.name}
                formName={props.btnAction.target}
                element={`button`}
                bg={`bg-green-800`}
                hoverBg={`bg-green-900`}
                hoverFg={`text-white`}
                classes={`w-2/4 flex-1 mr-2`}
                // onChange={handleSubmit}
              >
                {props.btnAction.cta}
              </Button>
              <Button
                btnType={`button`}
                element={`button`}
                triggerClass="modal-close"
                bg={`bg-red-800`}
                hoverBg={`bg-red-900`}
                classes={`flex-0`}
              >
                Close
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Modal
