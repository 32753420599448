import React from 'react'
import { graphql } from 'gatsby'
import loadable from '@loadable/component'
//
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import { Container, Row, Column } from '../components/structure'
import GraphQLErrorList from '../components/graphql-error-list'
import ProjectPreviewGrid from '../components/project-preview-grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import BlockContent from '../components/block-content'
import { QuoteCTACard, TestimonialCard } from '../components/elements/cards'
import Sidebar from '../components/elements/sidebar'
//
const PageHero = loadable(() => import('../components/page-hero'))

export const query = graphql`
  query ProjectPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    page: sanityPage(slug: { current: { regex: "/(drafts.|)projects/" } }) {
      id
      title
      _rawBody
      mainImage {
        asset {
          _id
          metadata {
            lqip
            dimensions {
              aspectRatio
            }
          }
          fluid(maxWidth: 1600) {
            ...GatsbySanityImageFluid
          }
        }
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        alt
      }
    }
    projects: allSanityProject(
      limit: 6
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          mainImage {
            crop {
              _key
              _type
              top
              bottom
              left
              right
            }
            hotspot {
              _key
              _type
              x
              y
              height
              width
            }
            asset {
              _id
              metadata {
                lqip
                dimensions {
                  aspectRatio
                }
              }
              fluid(maxHeight: 460) {
                ...GatsbySanityImageFluid
              }
              fixed(width: 850) {
                ...GatsbySanityImageFixed
              }
            }
            alt
          }
          title
          description
          slug {
            current
          }
        }
      }
    }
  }
`

const ProjectPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const { mainImage, title, _rawBody } = data && data.page
  const projectNodes = (data || {}).projects
    ? mapEdgesToNodes(data.projects)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : []

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:4444 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />

      {/*mainImage && mainImage.asset && <PageHero image={mainImage} strapline={title} />*/}

      <Container>
        <Row cols={3}>
          <Column colSpan={2}>
            <h1 className={`text-5xl font-display font-bold text-brand-mainalpha70`}>{title}</h1>
            <BlockContent blocks={_rawBody || []} withFirst />
            {projectNodes && (
              <ProjectPreviewGrid
                title="Latest projects"
                nodes={projectNodes}
                browseMoreHref="/projects/"
                cols={[2, 3, 3]}
              />
            )}
          </Column>
          <Sidebar>
            <QuoteCTACard />
            <TestimonialCard />
          </Sidebar>
        </Row>
      </Container>
    </Layout>
  )
}

export default ProjectPage
