import React from 'react'
//
import { Column } from '../structure'
//

const Sidebar = props => {
  console.log(props.classes)

  return (
    <Column
      classes={`sidebar flex flex-wrap superstock:flex-no-wrap supersport:flex-wrap content-center supersport:content-start supersport:order-2 justify-between supersport:justify-center items-stretch supersport:items-start px-4 mt-6 superbike:mt-0 mb-6 supersport:mb-0 ${props.classes}`}
    >
      {props.children}
    </Column>
  )
}

export default Sidebar
