import React from 'react'
//

const Card = props => {
  return (
    <div className="supersport:block w-full flex-auto supersport:flex-auto superstock:w-1/2 supersport:w-full rounded bg-brand-mainalpha70 text-white overflow-hidden shadow-md p-4 supersport:p-6 m-2 supersport:mb-4 testimonial-card">
      {props.children}
    </div>
  )
}

export default Card
